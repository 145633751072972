
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import DataImport from "@/components/scope/data-import.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";
import { Table, TableRowsSelect } from "@/utils/decorator";
import t from "@common/src/i18n/t";
import Template from "@/views/dashboard/template.vue";

const setting = namespace("setting");

@Component({
  components: { Template, TableEnableFlag, DataImport, SwitchStatus }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.select-spare-parts"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
@TableRowsSelect({
  warningMsg: t("v210831.please-select-the-synchronize-parts"),
  updateStatusFuncName: "updateAsync",
  tableRefKey: "table"
})
export default class PartsList extends Vue {
  @setting.Action getMenuList;
  @setting.Action saveMenuAndResource;
  @setting.Action delMenuAndResource;
  @setting.Action getMenuByCode;
  @setting.Action updateMenuAndResource;


  searchForm = {
    partsName: "",
    partsNumber: "",
    brandCode: "",
    categoryCode: ""
  };
  saveForm = {
    menuName: "",
    menuIcon: "",
    resourceType: "",
    pageUrl: "",
    displayOrder: "",
    menuParent: "",
    menuParentName: "",
    menuCode: "",
    status: 1,
    resourceCode:"",
    parentResourceCode:"",
    parentMenuCode:""
  };
  updateForm = {
    menuName: "",
    menuIcon: "",
    resourceType: "",
    pageUrl: "",
    displayOrder: "",
    menuParent: "",
    menuParentName: "",
    menuCode: "",
    status: 1,
    resourceCode:"",
    parentResourceCode:"",
    parentMenuCode:""
  };
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  sortForm: {};
  partsList: any[] = [];
  dialogAddFormVisible: boolean = false;
  dialogAddParentFormVisible: boolean = false;
  dialogUpdateFormVisible: boolean = false;
  saveFormDataRules = {
    menuName: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
    menuParent: [{ required: true, message: "请选择上级节点", trigger: "blur" }],
    displayOrder: [{ required: true, message: "请输入排序序号", trigger: "blur" }]
  };

  updateFormDataRules = {
    menuName: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
    menuParent: [{ required: true, message: "请选择上级节点", trigger: "blur" }],
    displayOrder: [{ required: true, message: "请输入排序序号", trigger: "blur" }]
  };

  data() {
    return {
      treeSelectProps: {
        value: "menuCode",
        label: "menuName",
        children: "children"
      }
    };
  }
  //添加菜单
  submitAddFormVisible(formName){
    (this.$refs[formName] as any).validate(valid => {
      console.log("submitAddFormVisible valid：" + valid);
      console.log("submitAddFormVisible json:" + JSON.stringify(this.saveForm));

      return this.saveMenuAndResource({
        ...this.saveForm,
        status: 1
      }).then(data => {
        if (data.code == 0) {
          this.loadListData();
          this.dialogAddFormVisible = false;
          this.dialogAddParentFormVisible = false;
          //表单清空
          Object.keys(this.saveForm).forEach(key => {
            this.saveForm[key] = '';
          });
        }
      });
    });
  }

  //修改菜单
  submitUpdateFormVisible(formName){
    (this.$refs[formName] as any).validate(valid => {
      console.log("submitUpdateFormVisible valid：" + valid);
      console.log("submitUpdateFormVisible json:" + JSON.stringify(this.updateForm));
      return this.updateMenuAndResource({
        menuCode: this.updateForm.menuCode,
        pageUrl: this.updateForm.pageUrl,
        menuIcon: this.updateForm.menuIcon,
        displayOrder: this.updateForm.displayOrder,
        menuName: this.updateForm.menuName,
        menuParent: this.updateForm.menuParent
      }).then(data => {
        if (data.code == 0) {
          this.loadListData();
          this.dialogUpdateFormVisible = false;
          //表单清空
          Object.keys(this.updateForm).forEach(key => {
            this.updateForm[key] = "";
          });
        }
      });
    });
  }
  getResourceTypeName(row) {
    if (row.children !== null && row.children.length > 0) {
      return "目录";
    }
    return "菜单";
  }

  categoryList: any[] = [];
  brandList: any[] = [];
  created() {
    this.init();
  }
  async init() {
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getMenuList({
      // ...this.searchForm,
      menuParent:"op_root",
      pageNum: this.mix_pageNum,
      pageSize: 999999,
    }).then(data => {
      this.partsList = data.data.list;
      return data;
    });
  }

  @TableRowsSelect.updateMethod
  confirmUpdateStatus(status, selectedRow) {
    // this.updateStatus({
    //   status: status,
    //   partsCode: selectedRow ? [selectedRow.partsCode] : this.mix_selectedRows.map(item => item.partsCode)
    // }).then(data => {
    //   this.$message.success(status ? t("setting.enable-successful") : t("setting.disable-success"));
    //   this.loadListData();
    // });
  }

  handleAdd(row) {
    this.dialogAddFormVisible = true;
    this.saveForm.menuParentName = row.menuName;
    this.saveForm.parentResourceCode = row.resourceCode;
    this.saveForm.parentMenuCode = row.menuCode;
    this.saveForm.menuParent = row.menuCode;
  }

  handleAddParent() {
    this.dialogAddParentFormVisible = true;
    this.saveForm.menuParentName = "op_root";
    this.saveForm.parentResourceCode = "op_root";
    this.saveForm.parentMenuCode = "op_root";
    this.saveForm.menuParent = "op_root";
  }
  handleEdit(row) {
    // 第一个请求
    const request1 = this.getMenuByCode({
      menuCode: row.menuCode
    });

    // 第二个请求
    const request2 = this.getMenuByCode({
      menuCode: row.menuParent
    });

    // 使用 Promise.all 等待两个请求都完成
    Promise.all([request1, request2]).then(results => {
      const [data1, data2] = results;

      if (data1.code === "0") {
        this.updateForm = data1.data;
      }

      if (data2.code === "0") {
        this.updateForm.menuParentName = data2.data.menuName;
        this.updateForm.parentResourceCode = data2.data.resourceCode;
        this.updateForm.parentMenuCode = data2.data.menuCode;
        this.updateForm.menuParent = data2.data.menuCode;
      }

      // 当两个请求都成功后，展示表单
      this.dialogUpdateFormVisible = true;
    });
  }

  handleDel(row) {
    console.log("即将删除的id:" + row.id);
    this.$confirm("删除该菜单同时也会删除账号授予的菜单权限？", "删除提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      this.delMenuAndResource({
        id: row.id
      }).then(data => {
        if (data.code == 0) {
          this.loadListData();
          //表单清空
          Object.keys(this.saveForm).forEach(key => {
            this.saveForm[key] = "";
          });
        }
      });
    });
  }
  handleView(row) {
    this.$router.push(`/management/parts-edit/${row.partsCode}?isView=1`);
  }

  departmentClick (data) {
    this.saveForm.menuParent = data.menuCode;
    this.saveForm.menuParentName = data.menuName;

    this.saveForm.parentMenuCode = data.menuCode;
    this.saveForm.parentResourceCode = data.resourceCode;
    //点击后关闭
    // this.$refs.treeSelect.visible = false;
  }

  departmentUpdateClick (data) {
    this.updateForm.menuParent = data.menuCode;
    this.updateForm.menuParentName = data.menuName;

    this.updateForm.parentMenuCode = data.menuCode;
    this.updateForm.parentResourceCode = data.resourceCode;
    //点击后关闭
    // this.$refs.updateTreeSelect.visible = false;
  }

  row = undefined;
}
